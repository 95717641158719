








import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import DashboardComponent from '@/components/dashboards/DashboardComponent.vue';

@Component({
    components: {
        'dashboard-component': DashboardComponent,
    },
})
export default class EmbedWidget extends Vue {
    public template: any = JSON.parse(`
        {
            "type": "card",
            "indicator": {
                "context": "building",
                "name": "qai",
                "id": "{building_id}"
            },
            "options": {
                "title": {
                    "text": "Qualité de l'air intérieur"
                },
                "subtitle": {
                    "text": "",
                    "hidden": true
                },
                "value": {
                    "use-mapping": true
                },
                "icon": {
                    "use-mapping": true
                },
                "graph": {
                    "type": "bar"
                }
            }
        }
    `);

    private variables: any = {
        building_id: 8,
    };
    private duration: any = '24h';

    private created() {
        this.$store.state.ready = true;
        this.$store.dispatch('preload');

        if (this.variables.building_id) {
        //   this.variables.building = this.$store.getters.getBuildingFromId(
        //     this.variables.building_id);
            this.$store.dispatch('ensureBuilding', {
                buildingId: this.variables.building_id,
                params: { recursive: true },
            })
            .then((response: any) => {
                this.variables.building = response;
            })
            .catch((error: any) => {
                this.globalError(error);
            });
        }
        if (this.variables.area_id) {
          this.variables.area = this.$store.getters.getAreaFromId(
            this.variables.area_id);
        }
    }

}
